var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('JoszakiCard',{staticClass:"grid grid-cols-[40px_1fr] gap-3"},[_c('UserAvatar',{attrs:{"image":_vm.$helpers.getProfilePictureThumbnailUrl(_vm.question.person.profilePhotoId)}}),_c('div',[_c('div',{staticClass:"flex justify-between"},[_c('NuxtLink',{staticClass:"flex-1",attrs:{"to":_vm.localePath({
            name: 'questionPage',
            params: { questionSeoName: _vm.question.seoName },
          })},on:{"click":function($event){return _vm.$trackers.trackClick('CardQuestionTitle')}}},[_c('h2',{staticClass:"text-primary font-bold md:text-xl first-letter:capitalize"},[_vm._v(" "+_vm._s(_vm.question.title)+" ")])]),_c('div',{staticClass:"hidden md:flex items-start gap-2"},[(_vm.question.profession)?_c('JoszakiPill',{attrs:{"to":_vm.localePath({
              name: 'professionQuestions',
              params: { profession: _vm.question.profession.seoName },
            }),"component":"NuxtLink"},on:{"click":function($event){return _vm.$trackers.trackClick('CardQuestionProfession')}}},[_vm._v(" "+_vm._s(_vm.question.profession.name)+" ")]):_vm._e(),(_vm.question.professionTask)?_c('JoszakiPill',{attrs:{"component":"NuxtLink","inverted":true,"to":_vm.localePath({
              name: 'professionTaskQuestions',
              params: { professionTask: _vm.question.professionTask.seoName },
            })},on:{"click":function($event){return _vm.$trackers.trackClick('CardQuestionProfessionTask')}}},[_vm._v(" "+_vm._s(_vm.question.professionTask.name)+" ")]):_vm._e()],1)],1),_c('p',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.question.body)+" ")]),_c('div',{staticClass:"flex items-center justify-between mt-4"},[_c('p',{staticClass:"text-xs font-normal text-black/50"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.question.created))+" ")]),_c('NuxtLink',{attrs:{"to":_vm.localePath({
            name: 'questionPage',
            params: { questionSeoName: _vm.question.seoName },
          })},on:{"click":function($event){return _vm.$trackers.trackClick('CardQuestionAnswerer')}}},[_c('div',{staticClass:"flex"},[_vm._l((_vm.answerers),function(answerer){return _c('div',{key:answerer.id,staticClass:"h-9 -ml-2.5"},[_c('UserAvatar',{attrs:{"image":_vm.$helpers.getProfilePictureThumbnailUrl(
                  answerer.profilePhotoId
                ),"is-frameless":true}})],1)}),(_vm.question.answererCount > 2)?_c('div',{staticClass:"h-9 -ml-2.5"},[_c('UserAvatar',{attrs:{"text":`+${_vm.question.answererCount - 2}`,"is-frameless":true}})],1):_vm._e()],2)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }