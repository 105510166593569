<template>
  <div class="flex flex-col gap-5 items-center mt-5">
    <IconComponent icon="empty-set" class="!text-4xl text-gray-300" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "JoszakiEmptyMessage",
};
</script>
