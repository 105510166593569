
import { defineComponent } from "vue";
import UserAvatar from "~/components/UserAvatar.vue";
import { differenceToNow } from "~/helpers/date";
import JoszakiPill from "@/components/_refactored/common/JoszakiPill.vue";

export default defineComponent({
  name: "CardQuestion",
  components: { UserAvatar, JoszakiPill },
  props: {
    question: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    answerers() {
      if (this.question.answerers) {
        return this.question.answerers.slice(0, 2);
      }
      return [];
    },
  },
  methods: {
    formatDate(date: string) {
      return differenceToNow(this as any, date);
    },
  },
});
