<template>
  <JoszakiPage :page-name="pageName" inner-class="flex flex-col gap-5">
    <BreadCrumbs :links="breadCrumbs" />

    <QuestionsPageHeader />

    <p class="text-center">
      <i18n path="questionsPage.searchOrRequestTender" :tag="false">
        <template #requestTender>
          <NuxtLink
            :to="localePath({ name: 'requestForTender' })"
            target="_blank"
          >
            {{ $t("questionsPage.requestTender") }}
          </NuxtLink>
        </template>
      </i18n>
    </p>

    <QuestionInputSearch
      ref="searchBox"
      :value.sync="searchTerm"
      :placeholder="searchPlaceholder"
      :search="search"
      is-standalone
      is-large
      should-show-magnifier
    />

    <BlockTender
      text-input
      class="hidden md:block"
      @requestTender="
        onRequestTender({ source: 'banner-block', description: $event })
      "
    />

    <JoszakiButton
      class="md:hidden font-bold"
      @click="onRequestTender({ source: 'banner-block' })"
    >
      {{ $t("requestTender.freeRequestTender") }}
    </JoszakiButton>

    <nav
      class="flex justify-between flex-col lg:flex-row max-w-[95vw] lg:max-w-full border-b"
    >
      <span class="py-3">
        <BreadCrumbs :links="breadCrumbs" class="!text-base !font-normal" />
      </span>
      <ul class="flex flex-row overflow-x-auto gap-2">
        <li
          v-for="key in orderOptions"
          :key="key"
          class="px-1 md:!px-4 !py-3 cursor-pointer rounded-t-md"
          :class="{
            'bg-gray-600/5 !text-primary font-bold': key === orderQuery,
            'hover:bg-gray-600/5': key !== orderQuery,
          }"
          @click="orderBy(key)"
        >
          {{ $t(`questionsPage.search.order.label.${key}`) }}
        </li>
      </ul>
    </nav>

    <span class="text-sm">{{
      $t("questionsPage.search.resultCount", {
        resultCount: questionsPagination?.total ?? 0,
      })
    }}</span>

    <div v-if="questions && questions.length" class="grid grid-cols-1 gap-4">
      <template v-if="isLoading">
        <CardQuestionPlaceholder v-for="i in 10" :key="i" />
      </template>
      <template v-else>
        <CardQuestion
          v-for="question in questions"
          :key="question.id"
          :question="question"
        />
      </template>

      <CtaBanner
        :title="$t('questionsPage.cta.wantToReadMoreTitle')"
        :button-label="$t('questionsPage.cta.wantToReadMoreButton')"
        :external-url="true"
        button-url="https://joszaki.hu/blog/"
      />
    </div>
    <JoszakiEmptyMessage v-else>
      <p>
        <i18n :path="`questionsPage.search.empty.info.${emptyLocalizationKey}`">
          <template #searchQuery>
            <strong>{{ searchQuery }}</strong>
          </template>
          <template #topic>
            <strong>{{ filter?.name }}</strong>
          </template>
        </i18n>
      </p>
      <p>
        <i18n
          :path="`questionsPage.search.empty.action.${emptyLocalizationKey}`"
        >
          <template #resetFilters>
            <NuxtLink :to="emptyActionUrl" class="font-bold">
              {{ $t("questionsPage.search.empty.action.resetFilters") }}
            </NuxtLink>
          </template>
        </i18n>
      </p>
    </JoszakiEmptyMessage>

    <PaginationComponent
      v-if="questionsPagination"
      :pagination-info="questionsPagination"
      class="mt-4"
    />
  </JoszakiPage>
</template>

<router>
{
name: 'questionsPage',
}
</router>

<script>
import QuestionInputSearch from "~/components/QuestionInputSearch";
import PaginationComponent from "~/components/_refactored/common/PaginationComponent.vue";
import CtaBanner from "~/components/_refactored/common/CtaBanner.vue";
import QuestionsPageHeader from "~/components/_refactored/questionsPage/QuestionsPageHeader.vue";
import BlockTender from "~/components/_refactored/blocks/BlockTender.vue";
import CardQuestionPlaceholder from "~/components/_refactored/card/CardQuestionPlaceholder.vue";
import CardQuestion from "~/components/_refactored/card/CardQuestion.vue";
import { useRequestTender } from "~/composables/requestTender";
import BreadCrumbs from "~/components/_refactored/BreadCrumbs.vue";
import { EVENT_PAGE_NAMES } from "~/data/pageNames";
import JoszakiEmptyMessage from "~/components/_refactored/common/JoszakiEmptyMessage.vue";

export default {
  name: "QuestionSearchPage",
  components: {
    BreadCrumbs,
    CardQuestion,
    CardQuestionPlaceholder,
    BlockTender,
    QuestionsPageHeader,
    QuestionInputSearch,
    PaginationComponent,
    CtaBanner,
    JoszakiEmptyMessage,
  },
  layout: "DefaultLayout",
  setup() {
    return {
      pageName: EVENT_PAGE_NAMES.QUESTION_ANSWER_PAGE,
      ...useRequestTender(),
    };
  },
  async asyncData(context) {
    const {
      profession: professionSeoName,
      professionTask: professionTaskSeoName,
    } = context.route.params;
    const {
      s: searchQuery,
      by: orderQuery = "new",
      page: pageQuery,
    } = context.route.query;

    let compiledData = {
      searchTerm: searchQuery,
      searchQuery,
      orderQuery,
    };

    const filters = {
      all: {},
      new: {},
      popular: {},
      unanswered: { maxAnswerCount: 0 },
    };

    const orders = {
      all: {},
      new: { by: "created", order: "desc" },
      popular: { by: "answerCount", order: "desc" },
      unanswered: { by: "created", order: "desc" },
    };

    try {
      const QUESTION_LIST_SSR = await import(
        "@/graphql/questionListSSR.graphql"
      );

      async function callQuery() {
        return await context.$query(QUESTION_LIST_SSR, {
          profession: professionSeoName,
          professionTask: professionTaskSeoName,
          questions: {
            filter: {
              search: searchQuery,
              profession: professionSeoName,
              professionTask: professionTaskSeoName,
              ...filters[orderQuery],
            },
            order: orders[orderQuery],
            requestedPage: pageQuery ? Number(pageQuery) : 1,
            limit: 10,
          },
        });
      }

      const {
        profession,
        professionTaskBySeoName: professionTask,
        questions,
      } = await callQuery();

      let filter;

      if (profession) {
        filter = profession;
        filter.type = "profession";
      } else if (professionTask) {
        filter = professionTask;
        filter.type = "professionTask";
      }

      compiledData = {
        ...compiledData,
        filter,
        questions: questions.questions,
        questionsPagination: questions.pagination,
      };
    } catch (error) {
      console.error(error);

      if (error.graphQLErrors) {
        context.$sentry.captureException(JSON.stringify(error.graphQLErrors), {
          tags: {
            page: "szakivalaszol",
          },
        });
      }

      context.error({
        statusCode: 404,
        message: context.i18n.t("error.codes.ENTITY_NOT_FOUND"),
      });
    }

    // Redirect to base view on invalid filter
    if ((professionSeoName || professionTaskSeoName) && !compiledData.filter) {
      context.redirect({ path: "/szakivalaszol", query: context.route.query });
    } else {
      return compiledData;
    }
  },

  data: () => ({
    isLoading: false,
  }),

  head() {
    const meta = [
      {
        hid: "description",
        name: "description",
        content: this.filter
          ? this.$t("questionsPage.search.head.description.filtered", {
              filterName: this.filter.name,
            })
          : this.$t("questionsPage.search.head.description.generic"),
      },
    ];

    if (this.questions.length === 0) {
      meta.push({
        hid: "robots",
        name: "robots",
        content: "noindex",
      });
    }
    return {
      title: this.filter
        ? this.$t("questionsPage.search.head.title.filtered", {
            filterName: this.filter.name,
          })
        : this.$t("questionsPage.search.head.title.generic"),
      meta,
    };
  },
  computed: {
    searchPlaceholder() {
      return this.filter
        ? this.$t("questionsPage.search.field.placeholder.filtered", {
            filterName: this.filter.name,
          })
        : this.$t("questionsPage.search.field.placeholder.generic");
    },
    emptyLocalizationKey() {
      return `${this.searchQuery ? "search" : "noSearch"}${
        this.filter && this.orderQuery === "unanswered" ? "Filtered" : ""
      }`;
    },
    emptyActionUrl() {
      if (!this.filter) {
        return this.localePath({ name: "questionsPage" });
      }

      if (this.filter.type === "profession") {
        return this.localePath({
          ...this.$route,
          query: {},
        });
      }

      if (this.filter.type === "professionTask") {
        return this.localePath({
          ...this.$route,
          query: {},
        });
      }

      return null;
    },
    orderOptions() {
      return ["all", "new", "popular", "unanswered"];
    },
    breadCrumbs() {
      const links = [
        {
          name: this.$t("questionsPage.title"),
          page: this.localePath({ name: "questionsPage" }),
        },
      ];

      if (this.filter?.type === "profession") {
        links.push({
          name: this.filter.name,
          page: this.localePath({
            name: "professionQuestions",
            params: { profession: this.filter.seoName },
          }),
        });
      }

      if (this.filter?.type === "professionTask") {
        links.push({
          name: this.filter.name,
          page: this.localePath({
            name: "professionTaskQuestions",
            params: { professionTask: this.filter.seoName },
          }),
        });
      }

      return links;
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.loadMore(this.$route.query.page ?? 1);

        if (newVal?.query?.page !== oldVal?.query?.page) {
          this.$refs.searchBox.$el.scrollIntoView();
        }
      },
    },
  },
  watchQuery: ["s", "by"],
  methods: {
    async loadMore(page = 1) {
      this.isLoading = true;
      const filters = {
        all: {},
        new: {},
        popular: {},
        unanswered: { maxAnswerCount: 0 },
      };

      const orders = {
        all: {},
        new: { by: "publishedAt", order: "desc" },
        popular: { by: "answerCount", order: "desc" },
        unanswered: { by: "publishedAt", order: "desc" },
      };
      const QUESTION_LIST = await import("@/graphql/questionList.graphql");
      const result = await this.$query(QUESTION_LIST, {
        questions: {
          filter: {
            search: this.searchQuery,
            profession: this.$route.params.profession,
            professionTask: this.$route.params.professionTask,
            ...filters[this.orderQuery],
          },
          order: orders[this.orderQuery],
          requestedPage: parseInt(page),
          limit: 10,
        },
      });

      this.questions = result.questions.questions;
      this.questionsPagination = result.questions.pagination;
      this.isLoading = false;
    },
    orderBy(aspect) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          by: aspect,
          page: 1,
        },
      });
    },
    search() {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          s: this.searchTerm,
          page: 1,
        },
      });
    },
    onRequestTender({ source, description }) {
      this.requestTender({
        source,
        description,
      });
    },
  },
};
</script>
