<template>
  <div
    class="w-full px-10 py-10 bg-yellow-400 rounded-md shadow-md bg-center bg-cover cursor-pointer bg-cta-banner"
    @click.prevent.stop="navigate()"
  >
    <JoszakiHeading class="text-gray-600" :level="2">
      {{ title }}
    </JoszakiHeading>
    <p v-if="description" class="w-2/3">
      {{ description }}
    </p>
    <a
      v-if="externalUrl"
      target="_blank"
      :href="buttonUrl"
      class="mt-3 inline-block text-white px-5 w-auto uppercase transition-colors py-2 bg-primary rounded-[4px]"
    >
      {{ buttonLabel }}
    </a>
    <NuxtLink
      v-else
      :to="buttonUrl"
      class="mt-3 inline-block text-white px-5 w-auto uppercase transition-colors py-2 bg-primary rounded-[4px]"
    >
      {{ buttonLabel }}
    </NuxtLink>
  </div>
</template>

<script>
export default {
  name: "CtaBanner",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    buttonUrl: {
      type: String,
      required: true,
    },
    externalUrl: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigate() {
      if (this.externalUrl) {
        window.open(this.buttonUrl, "_blank");
      } else {
        this.$router.push(this.buttonUrl);
      }
    },
  },
};
</script>
